import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography, Box } from "@mui/material";
import db from "../utils/db";

export default function Detail() {
  const [ticket, setTicket] = useState(null);
  const [storedData, setStoredData] = useState([]);
  const [fetch, setFetch] = useState(0);

  useEffect(() => {
    const id = localStorage.getItem("idTicket");

    const fetchTicket = async () => {
      const ticket = await db.tickets.get({ id: id });
      const productos = await db.productos.toArray();
      const producto = productos.find(
        (producto) => producto.CodigoProducto == ticket.producto
      );
      setTicket(ticket);

      const newData = [
        { label: "Inicio", value: ticket.inicio },
        { label: "Fin", value: ticket.fin },
        { label: "Cliente", value: ticket.cliente },
        {
          label: "Entrega",
          value: ticket.entregaBruta,
        },
        {
          label: "T. Inicial",
          value: ticket.estadoInicial,
        },
        {
          label: "T. Final",
          value: ticket.estadoFinal,
        },
        {
          label: "Chofer",
          value: ticket.choferNombre,
        },
        {
          label: "Patente",
          value: ticket.patente,
        },
        ...(ticket.operacionVenta !== "Ledesma Tanques"
          ? [
              {
                label: "Máquina",
                value: (ticket.cliente === "Tabacal"
                  ? ticket.maquinaDescripcion
                  : ticket.maquina
                ).substring(0, 27), // Acorta a 27 caracteres
              },
              {
                label: "Horómetro",
                value: ticket.horometro,
              },
              ...(ticket.operacionVenta !== "Ledesma Capilar"
                ? [
                    {
                      label: "Operación",
                      value: ticket.operacion,
                    },
                    {
                      label: "Producto",
                      value: producto.DescripcionArticulo,
                    },
                  ]
                : []),
              {
                label: "Bloque",
                value: ticket.bloqueDescripcion,
              },
              {
                label: "Supervisor",
                value: ticket.supervisorLegajo + ": " + ticket.supervisorNombre,
              },
            ]
          : []),
        ...(ticket.cuarteo == "true"
          ? [
              {
                label: "Cuarteo",
                value: "Sí",
              },
            ]
          : []),
        {
          label: "Número de ticket",
          value: ticket.numeroVenta,
        },
      ];

      // Condicion para el cliente Tabacal
      if (ticket.cliente === "Tabacal") {
        newData.push({
          label: "Urea",
          value: ticket.urea + " litros",
        });
      }

      setStoredData(newData);
    };
    if (id != null) {
      fetchTicket();
    } else {
      console.log("estoy acá", id);
      setFetch((fetch) => fetch + 1);
    }
  }, [fetch]);

  return (
    <Box sx={{ marginBottom: 3, marginTop: 1 }}>
      {ticket != null && (
        <Box>
          {storedData.map(({ label, value }) => (
            <Typography
              sx={{
                fontSize: "12px",
                "@media print": {
                  fontSize: "5px",
                  marginLeft: ticket.cliente !== "Tabacal" ? "90px" : 0,
                },
              }}
              key={label}
              variant="body2"
            >
              <strong>{label}:</strong> {value}
            </Typography>
          ))}
          <Box
            sx={{
              paddingTop: "10px",
              "@media print": {
                marginLeft: ticket.cliente !== "Tabacal" ? "90px" : 0,
              },
            }}
          >
            {ticket.firma != undefined && (
              <img src={ticket.firma} alt="Signature" width="100px" />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
