import { useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintIcon from "@mui/icons-material/Print";
import { GlobalContext } from "../context/GlobalContext";
import EditIcon from "@mui/icons-material/Edit";
function TicketList({ tickets }) {
  const { context, setContext } = useContext(GlobalContext);
  const [selectedMode, setSelectedMode] = useState({});
  const [ticketSelected, setTicketSelected] = useState({});
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const mode = localStorage.getItem("mode");
    if (mode !== "undefined") {
      setSelectedMode(JSON.parse(mode));
    }
  }, []);
  const handlePrint = (ticket) => {
    localStorage.setItem("idTicket", ticket.id);

    if (ticket.operacionVenta == "Agro") {
      setContext({
        ...context,
        page: ticket.emiteTicket == "false" ? "agroDetail" : "detail",
      });
    } else {
      setContext({
        ...context,
        page: "detail",
      });
    }
  };

  const handleEdit = () => {
    localStorage.setItem("idTicket", ticketSelected.id);
    setContext({
      ...context,
      page: "editTicket",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <List>
        {tickets.length == 0 && (
          <Typography sx={{ marginLeft: 3 }}>
            Presione el botón "+" para agregar el primer ticket.
          </Typography>
        )}
        {tickets.map((ticket) => (
          <Paper key={ticket.id} elevation={3}>
            <ListItem>
              <ListItemText
                primary={
                  "Ticket " + ticket.numeroVenta + ": " + ticket.entregaBruta
                }
              />
              <ListItemSecondaryAction sx={{ display: "flex", gap: 1 }}>
                <IconButton
                  edge="end"
                  aria-label="print"
                  onClick={() => {
                    handlePrint(ticket);
                  }}
                >
                  <PrintIcon />
                </IconButton>
                {ticket.operacionVenta !== "Agro" && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      setOpen(true);
                      setTicketSelected(ticket);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}

                {ticket.sent ? (
                  <IconButton edge="end" aria-label="sent">
                    <CheckCircleIcon color="success" />
                  </IconButton>
                ) : null}
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        ))}
      </List>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edición de Ticket"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Está seguro que desea editar el ticket {ticketSelected.numeroVenta}{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit} autoFocus>
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TicketList;
