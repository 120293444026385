import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
} from "@mui/material";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import DialogButtons from "./DialogButtons";
import { saveDispatch } from "../utils/tickets";
import { GlobalContext } from "../context/GlobalContext";
import { verifySalesNumber } from "../utils/helpers";
export default function CamScan() {
  const webcamRef = useRef(null);
  const intervalRef = useRef();
  const [active, setActive] = useState(true);
  const [codeProcessed, setCodeProcessed] = useState(false);
  const [qr, setQR] = useState(null);
  const [error, setError] = useState(null); // Estado para manejar errores
  const [videoDevices, setVideoDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const { context, setContext } = useContext(GlobalContext);
  const [selectedDeviceId, setSelectedDeviceId] = useState(() => {
    const savedCameraId = localStorage.getItem("selectedCameraId");
    return savedCameraId !== null ? savedCameraId : "";
  });

  useEffect(() => {
    getVideoDevices();
    intervalRef.current = setInterval(captureAndAnalyzeQR, 500);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const handleCameraChange = (e) => {
    const selectedDeviceId = e.target.value;
    setSelectedDeviceId(selectedDeviceId);
    localStorage.setItem("selectedCameraId", selectedDeviceId);
  };

  const getVideoDevices = async (attempts = 5, delay = 500) => {
    setLoadingDevices(true);
    let devices;
    let videoDevices = [];

    for (let i = 0; i < attempts; i++) {
      devices = await navigator.mediaDevices.enumerateDevices();
      videoDevices = devices.filter((device) => device.kind === "videoinput");

      // Si se encuentran cámaras, salir del bucle
      if (videoDevices.length > 0) break;

      // Si no se encuentran cámaras, esperar y reintentar
      await new Promise((resolve) => setTimeout(resolve, delay));
    }

    if (videoDevices.length === 0) {
      setError("No se encontraron cámaras.");
      setLoadingDevices(false);
      return;
    }

    // Identificar la cámara trasera
    const mainCamera = videoDevices.find((device) => {
      const capabilities = device.getCapabilities
        ? device.getCapabilities()
        : {};
      return (
        capabilities.facingMode &&
        (capabilities.facingMode.includes("environment") ||
          capabilities.facingMode.includes("rear"))
      );
    });

    // Si se encuentra una cámara trasera, usarla como predeterminada
    const defaultCameraId = mainCamera
      ? mainCamera.deviceId
      : videoDevices[0]?.deviceId;

    setSelectedDeviceId(defaultCameraId);
    localStorage.setItem("selectedCameraId", defaultCameraId);
    setVideoDevices(videoDevices);
    setLoadingDevices(false); // Finaliza el estado de carga
  };

  const decodeQRFromImage = async (imageData) => {
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    if (code) {
      setCodeProcessed(true);
      const translations = {
        "TIME START": "Inicio =",
        "INICIO TIEMPO": "Inicio =",
        "TIME END": "Fin =",
        "FIN DE TIEMPO": "Fin =",
        "END GROSS COUNT": "Estado Final =",
        "FIN DE CONTEO": "Estado Final =",
        "START COUNT": "Estado Inicial =",
        "INICIO CONTEO": "Estado Inicial =",
        "START TOTALIZER": "Inicio Totalizador =",
        "INICIO TOTALIZ.": "Inicio Totalizador =",
        "END TOTALIZER": "Fin Totalizador =",
        "FIN TOTALIZADOR": "Fin Totalizador =",
        LITRES: "litros",
        LITROS: "litros",
        START: "Inicio =",
        INICIO: "Inicio =",
        FINISH: "Fin =",
        FIN: "Fin =",
        "GROSS DELIVERY": "Entrega Bruta =",
        "ENTREGA BRUTO": "Entrega Bruta =",
        GASOLINE: "Combustible =",
        GASOLINA: "Combustible =",
        "SALE NUMBER": "Número de venta =",
        "NUMERO DE VENTA": "Número de venta =",
      };

      let translatedQr = code.data;

      for (let key in translations) {
        const regex = new RegExp(key, "g");
        translatedQr = translatedQr.replace(regex, translations[key]);
      }
      const existeTicket = await verifySalesNumber(translatedQr.split("\n"));
      if (existeTicket) {
        alert("El código QR que se intenta escanear ya fué escaneado.");
        localStorage.removeItem("idTicket");
        localStorage.removeItem("cuarteo");
        localStorage.removeItem("tabacalOperation");
        localStorage.removeItem("odometer");
        localStorage.removeItem("bloqueID");
        localStorage.removeItem("supervisorNombre");
        localStorage.removeItem("machine");
        localStorage.removeItem("supervisorLegajo");
        localStorage.removeItem("bloqueDescripcion");
        localStorage.removeItem("entregaBruta");
        setContext({
          ...context,
          page: "home",
          step: "step1",
        });
      } else {
        const qrData = translatedQr.split("\n");
        const validationError = validateQRData(qrData); // Validación de datos requeridos
        if (validationError) {
          setError(validationError); // Mostrar error si faltan datos
          return;
        }
        setError(null); // Limpiar error si el QR es válido
        setQR(qrData);
        setActive(false);
      }
    }
  };

  const validateQRData = (qrData) => {
    const requiredFields = [
      "Inicio",
      "Fin",
      "Estado Final",
      "Estado Inicial",
      "Inicio Totalizador",
      "Fin Totalizador",
      "Entrega Bruta",
      "Número de venta",
    ];
    const missingFields = requiredFields.filter(
      (field) => !qrData.some((line) => line.startsWith(`${field} =`))
    );

    return missingFields.length > 0
      ? `Faltan datos en el QR: ${missingFields.join(", ")}`
      : null;
  };

  const captureAndAnalyzeQR = () => {
    if (!codeProcessed && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        const imageData = ctx.getImageData(0, 0, image.width, image.height);
        decodeQRFromImage(imageData);
      };
    }
  };
  const videoConstraints = {
    deviceId: selectedDeviceId,
  };

  const handleNext = () => {
    const requiredFields = [
      "Inicio",
      "Fin",
      "Estado Final",
      "Estado Inicial",
      "Inicio Totalizador",
      "Fin Totalizador",
      "Entrega Bruta",
      "Número de venta",
    ];
    const missingFields = requiredFields.filter(
      (field) => !localStorage.getItem(field)
    );
    const inicio = localStorage.getItem("Inicio");
    const fin = localStorage.getItem("Fin");
    const inicioTotalizador = localStorage.getItem("Inicio Totalizador");
    const entregaBruta = localStorage.getItem("Entrega Bruta");
    if (missingFields.length > 0) {
      setError(`Faltan los siguientes datos: ${missingFields.join(", ")}`);
      return;
    }
    saveDispatch(parseFloat(entregaBruta), inicio, fin, inicioTotalizador);
  };
  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {active && (
        <Box sx={{ mt: "16px" }}>
          <Webcam
            audio={false}
            ref={webcamRef}
            style={{ width: "100%" }}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        </Box>
      )}
      {qr == null && (
        <>
          {loadingDevices ? (
            <Typography>Cargando cámaras...</Typography> // O utiliza un spinner de tu preferencia
          ) : (
            <FormControl sx={{ marginTop: 3, width: 300 }}>
              <InputLabel id="select-camera-label">
                Seleccione una cámara
              </InputLabel>
              <Select
                variant="standard"
                labelId="select-camera-label"
                value={selectedDeviceId}
                onChange={handleCameraChange}
              >
                {videoDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Cámara ${device.deviceId}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}

      {qr !== null && (
        <DialogContent>
          <DialogTitle>Datos del despacho</DialogTitle>
          {qr.map((item, index) => {
            const parts = item.split("=", 2);
            if (parts.length < 2) {
              return null;
            }
            const [label, value] = parts;
            localStorage.setItem(label.trim(), value.trim());
            return (
              <Typography key={index}>
                <b>{label}</b> : {value}
              </Typography>
            );
          })}
        </DialogContent>
      )}
      <DialogButtons
        onNext={() => {
          handleNext();
        }}
        showBack={qr == null}
        showNext={qr !== null}
      />
    </Box>
  );
}
