import React, { useEffect, useState, useContext } from "react";
import db from "../utils/db";
import { operations } from "../data/operationsClient";
import {
  Autocomplete,
  Box,
  TextField,
  DialogTitle,
  DialogContent,
  Container,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GlobalContext } from "../context/GlobalContext";

export default function EditTicket() {
  const { context, setContext } = useContext(GlobalContext);
  const [ticket, setTicket] = useState({});
  const [selectedTruck, setSelectedTruck] = useState({});
  const [trucks, setTrucks] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedOdometer, setSelectedOdometer] = useState("");
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [selectedCuarteo, setSelectedCuarteo] = useState(false);
  const [productos, setProductos] = useState(null);
  const [selectedProducto, setSelectedProducto] = useState(null);

  useEffect(() => {
    const id = localStorage.getItem("idTicket");
    const fetchData = async () => {
      const ticket = await db.tickets.get({ id: id });
      setTicket(ticket);
      console.log(ticket);
      db.trucks.toArray().then((trucks) => {
        setTrucks(trucks);
        const truck = trucks.find((truck) => truck.Patente == ticket.patente);
        setSelectedTruck(truck);
      });

      if (ticket.cliente == "Ledesma") {
        setDataLedesma(ticket);
      } else {
        setDataTabacal(ticket);
      }
    };
    fetchData();
  }, []);

  const setDataTabacal = (ticket) => {
    db.blocks.toArray().then((blocks) => {
      setBlocks(blocks);
      const block = blocks.find((block) => block.BloqueID == ticket.bloqueID);
      setSelectedBlock(block);
    });
    db.machine.toArray().then((machines) => {
      setMachines(machines);
      const machine = machines.find(
        (machine) => machine.MaquinaID == ticket.maquina
      );
      setSelectedMachine(machine);
    });
    db.supervisors.toArray().then((supervisors) => {
      setSupervisors(supervisors);
      const supervisor = supervisors.find(
        (supervisor) => supervisor.Legajo == ticket.supervisorLegajo
      );
      setSelectedSupervisor(supervisor);
    });
    db.productos.toArray().then((productos) => {
      setProductos(productos);
      const producto = productos.find(
        (producto) => producto.CodigoProducto == ticket.producto
      );
      console.log(producto);
      setSelectedProducto(producto);
    });

    const operation = operations.find(
      (operation) => operation.title == ticket.operacion
    );
    setSelectedOperation(operation);
    setSelectedOdometer(ticket.horometro);
    setSelectedCuarteo(ticket.cuarteo);
  };

  const setDataLedesma = (ticket) => {
    db.blocksLedesma.toArray().then((blocks) => {
      setBlocks(blocks);
      const block = blocks.find((block) => block.BloqueID == ticket.bloqueID);
      setSelectedBlock(block);
      console.log(block);
    });
    db.machineLedesma.toArray().then((machines) => {
      setMachines(machines);
      const machine = machines.find(
        (machine) => machine.MaquinaID == ticket.maquina
      );
      setSelectedMachine(machine);
    });
    db.supervisorsLedesma.toArray().then((supervisors) => {
      setSupervisors(supervisors);
      const supervisor = supervisors.find(
        (supervisor) => supervisor.Legajo == ticket.supervisorLegajo
      );
      setSelectedSupervisor(supervisor);
    });
    setSelectedOdometer(ticket.horometro);
  };

  const handleSave = async () => {
    ticket.patente = selectedTruck.Patente;
    ticket.sent = false;
    if (ticket.operacionVenta !== "Ledesma Tanques") {
      ticket.maquina = selectedMachine.MaquinaID;
      ticket.maquinaDescripcion = selectedMachine.Descripcion;
      ticket.horometro = selectedOdometer;
      ticket.bloqueID = selectedBlock.BloqueID.toString();
      ticket.bloqueDescripcion = selectedBlock.Descripcion;
      ticket.supervisorLegajo = selectedSupervisor.Legajo.toString();
      ticket.supervisorNombre = selectedSupervisor.NombreyApellido;

      if (ticket.operacionVenta === "Tabacal") {
        ticket.operacion = selectedOperation.title;
        ticket.producto = selectedProducto.CodigoProducto;
        ticket.cuarteo = selectedCuarteo;
      }
    }
    db.tickets.put(ticket).then((id) => {
      setContext({
        ...context,
        page: "home",
      });
    });
  };

  const handleCancel = () => {
    setContext({
      ...context,
      page: "home",
    });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "0 auto" }}>
      <DialogTitle>Campos a modificar</DialogTitle>
      <DialogContent>
        <Autocomplete
          value={selectedTruck}
          onChange={(event, newValue) => {
            setSelectedTruck(newValue);
          }}
          options={trucks}
          getOptionLabel={(option) => option.Patente}
          renderInput={(params) => (
            <TextField {...params} label="Camión" margin="normal" />
          )}
        />
        {ticket.operacionVenta !== "Ledesma Tanques" && (
          <Box>
            <Autocomplete
              options={machines}
              getOptionLabel={(option) => option.Descripcion}
              value={selectedMachine}
              onChange={(event, newValue) => {
                setSelectedMachine(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Máquina" margin="normal" />
              )}
            />
            <TextField
              label="Horómetro"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={selectedOdometer}
              sx={{ marginTop: 1, width: "100%" }}
              onChange={(event) => {
                setSelectedOdometer(event.target.value);
              }}
            />
            {ticket.cliente == "Tabacal" && (
              <Autocomplete
                options={operations}
                getOptionLabel={(option) => option.title}
                value={selectedOperation}
                onChange={(event, newValue) => {
                  setSelectedOperation(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Operación" margin="normal" />
                )}
              />
            )}

            {ticket.cliente == "Tabacal" && (
              <Autocomplete
                options={productos}
                getOptionLabel={(option) => `${option.DescripcionArticulo}`}
                value={selectedProducto}
                onChange={(event, value) => {
                  setSelectedProducto(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Producto" margin="normal" />
                )}
              />
            )}

            <Autocomplete
              options={blocks}
              getOptionLabel={(option) => option.Descripcion}
              value={selectedBlock}
              onChange={(event, newValue) => {
                setSelectedBlock(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Bloque" margin="normal" />
              )}
            />
            <Autocomplete
              options={supervisors}
              getOptionLabel={(option) => `${option.Descripcion}`}
              value={selectedSupervisor}
              onChange={(event, newValue) => {
                setSelectedSupervisor(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Supervisor" margin="normal" />
              )}
            />
            {ticket.cliente == "Tabacal" && (
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => setSelectedCuarteo(!selectedCuarteo)}
                    checked={selectedCuarteo}
                  />
                }
                label="Cuarteo"
              />
            )}
          </Box>
        )}
      </DialogContent>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          bottom: "20px",
        }}
      >
        <Typography
          sx={{
            marginRight: 2,
            "&:hover": {
              cursor: "pointer",
              opacity: 0.8,
            },
            width: "100%",
            textAlign: "left",
          }}
        >
          <Button onClick={() => handleCancel()}>Volver</Button>
        </Typography>
        <Typography
          sx={{
            "&:hover": {
              cursor: "pointer",
              opacity: 0.8,
            },
            width: "100%",
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => {
              handleSave();
            }}
          >
            Guardar
          </Button>
        </Typography>
      </Container>
    </Box>
  );
}
