import db from "./db";

export const verifySalesNumber = async (qr) => {
  const nuevoQR = [];
  qr.forEach((item) => {
    const parts = item.split("=", 2);
    if (parts.length >= 2) {
      const [label, value] = parts;
      const trimmedLabel = label.trim();
      const trimmedValue = value.trim();

      localStorage.setItem(trimmedLabel, trimmedValue);

      // Agregar objeto al array nuevoQR
      const obj = {};
      obj[trimmedLabel] = trimmedValue;
      nuevoQR.push(obj);
    }
  });
  const numeroVenta = obtenerNumeroVenta(nuevoQR);
  const truckData = localStorage.getItem("truck");
  const truck = JSON.parse(truckData);
  const tickets = await db.tickets.toArray();
  const existeTicket = tickets.find(
    (ticket) =>
      ticket.numeroVenta === numeroVenta && ticket.patente === truck.Patente
  );
  if (existeTicket) {
    return true;
  } else {
    return false;
  }
};

const obtenerNumeroVenta = (nuevoQR) => {
  const objetoEncontrado = nuevoQR.find(
    (objeto) => Object.keys(objeto)[0] === "Número de venta"
  );

  // Devolver el valor si se encuentra el objeto, de lo contrario, devolver null
  return objetoEncontrado ? Object.values(objetoEncontrado)[0] : null;
};
