import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TextField,
  DialogTitle,
  DialogContent,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GlobalContext } from "../context/GlobalContext";

const TabacalUrea = () => {
  const { context, setContext } = useContext(GlobalContext);
  const [formComplete, setFormComplete] = useState(true);
  const [urea, setUrea] = useState("");

  useEffect(() => {
    // Check if all fields are completed
    const isFormComplete = urea !== "" && urea >= 0 && urea <= 80;

    setFormComplete(isFormComplete);
  }, [urea]);

  const handleSave = async () => {
    localStorage.setItem("urea", urea);
    setContext({
      ...context,
      page: "newTicket",
      step: "step1",
    });
  };

  const handleCancel = () => {
    setContext({
      ...context,
      page: "settings",
    });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "0 auto" }}>
      <DialogTitle>Datos del despacho (Urea)</DialogTitle>
      <DialogContent>
        <TextField
          label="Urea"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 80 } }}
          value={urea}
          sx={{ marginTop: 1, width: "100%" }}
          onChange={(event) => {
            const value = event.target.value;
            // Verificar si el valor es un número y está dentro del rango permitido
            if (/^(0|[1-9][0-9]{0,1})?$/.test(value)) {
              if (
                value === "" ||
                (parseFloat(value) >= 0 && parseFloat(value) <= 80)
              ) {
                setUrea(value);
              }
            }
          }}
        />
      </DialogContent>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          bottom: "20px",
          right: "0px",
        }}
      >
        <Typography
          sx={{
            marginRight: 2,
            "&:hover": {
              cursor: "pointer",
              opacity: 0.8,
            },
            width: "100%",
            textAlign: "left",
          }}
        >
          <IconButton onClick={() => handleCancel()}>
            <ArrowBackIcon fontSize="large" color="primary" />
          </IconButton>
        </Typography>
        <Typography
          sx={{
            "&:hover": {
              cursor: "pointer",
              opacity: 0.8,
            },
            width: "100%",
            textAlign: "right",
          }}
        >
          <IconButton
            onClick={() => {
              handleSave();
            }}
            disabled={!formComplete}
          >
            <ArrowForwardIcon
              fontSize="large"
              color={formComplete ? "primary" : ""}
            />
          </IconButton>
        </Typography>
      </Container>
    </Box>
  );
};

export default TabacalUrea;
